import { Add, MenuBook } from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction, Button, Container, Grid, Paper, useMediaQuery, useTheme } from "@mui/material";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LayoutContext, persistStorage } from "wcz-layout";
import { SearchInput } from "../components/common/SearchInput";
import { RatingCard } from "../components/rating/RatingCard";
import { RatingCommentsDialog } from "../components/rating/RatingCommentsDialog";
import Rating from "../models/Rating";
import Reaction from "../models/Reaction";
import PaginationFilter from "../models/pagination/PaginationFilter";
import PaginationResponse from "../models/pagination/PaginationResponse";
import { useServerSideRatings } from "../services/RatingService";
import { useDebounce } from "use-debounce";

const storedReactions: Reaction[] | null = persistStorage.getObject("reactions");

export const HomePage: React.FC = () => {
    const { changeTitle, t, user } = useContext(LayoutContext);
    const [ratings, setRatings] = useState<Rating[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [ratingDetail, setRatingDetail] = useState<Rating>({} as Rating);
    const [reactions, setReactions] = useState<Reaction[]>(storedReactions ?? []);
    const [searchText, setSearchText] = useState<string>("");
    const [searchTextDebounced] = useDebounce(searchText, 700);
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => changeTitle("Rate canteen"), []);

    useEffect(() => {
        getRatings();
    }, [searchTextDebounced]);

    const getPaginationFilter = useCallback((): PaginationFilter => {
        const imageFilter = searchText === "With images" || searchText === "S obrázky";
        if (imageFilter)
            return {
                pageSize: pageSize,
                orderBy: ["created desc"],
                advancedFilter: {
                    logic: "and",
                    filters: [
                        {
                            field: "hasFile",
                            operator: "eq",
                            value: true
                        },
                    ]
                }
            };

        const myRatings = searchText === "My ratings" || searchText === "Moje hodnocení";
        if (myRatings && user.name)
            return {
                pageSize: pageSize,
                orderBy: ["created desc"],
                advancedFilter: {
                    logic: "and",
                    filters: [
                        {
                            field: "userName",
                            operator: "contains",
                            value: user.name
                        },
                    ]
                }
            };

        return {
            pageSize: pageSize,
            orderBy: ["created desc"],
            keyword: searchText ?? undefined
        };
    }, [pageSize, searchText, user]);

    const { data = {} as PaginationResponse<Rating>, refetch: getRatings, isFetching } = useServerSideRatings("search", getPaginationFilter(), {
        refetchOnWindowFocus: true,
        refetchInterval: ratingDetail.id ? undefined : 1000 * 60 * 0.5,
        onSuccess: data => {
            setRatings(data.data);

            if (ratingDetail.id) {
                const fetchedRating = data.data.find(rating => rating.id === ratingDetail.id);
                if (fetchedRating)
                    setRatingDetail(fetchedRating);
            }
        }
    });

    const handleLoadMore = useCallback(() => setPageSize(pageSize + 10), [pageSize]);

    useEffect(() => { getRatings(); }, [pageSize]);

    const handleOnAddRating = useCallback(() => navigate("/rating/add"), []);
    const handleOnMenuClick = useCallback(() => navigate("/menu"), []);

    const handleOnCommentsClick = useCallback((rating: Rating) => () => setRatingDetail(rating), []);

    const bottomPanel = (
        <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation showLabels>
                <BottomNavigationAction label="Menu" icon={<MenuBook />} onClick={handleOnMenuClick} />
                <BottomNavigationAction label={t("AddRating")} icon={<Add />} onClick={handleOnAddRating} />
            </BottomNavigation>
        </Paper>
    );

    return (
        <Fragment>
            <Container sx={{ mt: 2, mb: 10 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SearchInput searchText={searchText} setSearchText={setSearchText} />
                    </Grid>

                    {ratings.map(rating =>
                        <Grid item xs={12} key={rating.id}>
                            <RatingCard rating={rating} onCommentsClick={handleOnCommentsClick} reactions={reactions} setReactions={setReactions} />
                        </Grid>
                    )}
                </Grid>

                {data.hasNextPage && !isFetching &&
                    <Grid container justifyContent="center" sx={{ mt: 2 }}>
                        <Grid item>
                            <Button onClick={handleLoadMore} variant="contained" sx={{ textAlign: "center" }}>{t("TenMore")}</Button>
                        </Grid>
                    </Grid>
                }
            </Container>

            <RatingCommentsDialog rating={ratingDetail} setRating={setRatingDetail} reactions={reactions} setReactions={setReactions} />
            {isSmallScreen && bottomPanel}
        </Fragment>
    );
};