import { Grid, List, ListItem, ListSubheader, Typography } from "@mui/material";
import moment from "moment";
import "moment/locale/cs";
import "moment/locale/en-gb";
import React, { useCallback, useContext, useEffect } from "react";
import { LayoutContext } from "wcz-layout";
import { useGetMenus } from "../services/MenuService";
import { groupBy } from "lodash";
import MenuCard from "../components/menu/MenuCard";

export const MenuPage: React.FC = () => {
    const { changeTitle, t, i18n } = useContext(LayoutContext);

    useEffect(() => {
        changeTitle(t("WeeklyMenu"));
    }, [i18n.language]);

    const { data: menus = [] } = useGetMenus();

    const getTranslatedDay = useCallback((date: string) => {
        const localLocale = moment(date);
        if (i18n.resolvedLanguage === "cs")
            localLocale.locale("cs");

        return localLocale.format("D. dddd");
    }, [i18n.resolvedLanguage]);

    return (
        <List sx={{ position: "relative", overflow: "auto", "& ul": { padding: 0 }, height: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" } }} subheader={<li />}>
            {Object.keys(groupBy(menus, "servingDay")).map((key) => (
                <li key={key}>
                    <ul>
                        <ListSubheader sx={{ bgcolor: "transparent" }}>
                            <Typography variant="h5" sx={{ mt: 2 }}>{getTranslatedDay(key)}</Typography>
                        </ListSubheader>

                        {groupBy(menus, "servingDay")[key].map((menu) => (
                            <ListItem key={menu.foodName}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <MenuCard menu={menu} />
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ))}
                    </ul>
                </li>
            ))}
        </List>
    );
};