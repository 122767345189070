import { GridRowId } from "@mui/x-data-grid-pro";
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import { useContext } from "react";
import { fetchDelete, fetchGet, fetchPost, fetchPut, LayoutContext } from "wcz-layout";
import PaginationFilter from "../models/pagination/PaginationFilter";
import PaginationResponse from "../models/pagination/PaginationResponse";
import Rating from "../models/Rating";
import { apiUrl } from "../utils/BaseUrl";

const queryKey: string = "Ratings";

export function useGetRatings<TQueryFnData = Rating[], TError = string, TData = TQueryFnData>(options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([queryKey], ({ signal }) => fetchGet(`${apiUrl}/${queryKey}`, signal), options);
}

export function useGetRating<TQueryFnData = Rating, TError = string, TData = TQueryFnData>(id: GridRowId | undefined, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([queryKey, id], ({ signal }) => fetchGet(`${apiUrl}/${queryKey}/${id}`, signal), options);
}

export function useServerSideRatings<TQueryFnData = PaginationResponse<Rating>, TError = string, TData = TQueryFnData>(key: string, data: PaginationFilter, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([queryKey, key], () => fetchPost(`${apiUrl}/${queryKey}/search`, data), options);
}

interface UseCreateOptions {
    onSuccess?: (data: Rating) => void,
    onError?: (message: string) => void,
}

export function useCreateRating(options?: UseCreateOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: Rating) => fetchPost(`${apiUrl}/${queryKey}`, model), {
        onMutate: async (model) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });

            const previousDataList = queryClient.getQueryData<Rating[]>([queryKey]);
            if (previousDataList)
                queryClient.setQueryData([queryKey], [model, ...previousDataList]);

            return { previousDataList };
        },
        onError: (err: string, model, context) => {
            if (context) {
                queryClient.setQueryData([queryKey], context.previousDataList);
            }
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: async (model, variables, context) => {
            if (context) {
                queryClient.setQueryData([queryKey], [model, ...context.previousDataList ?? []]);
            }
            if (options?.onSuccess)
                options.onSuccess(model);

            await queryClient.invalidateQueries({ queryKey: [queryKey, "search"] });
        },
    });
}

interface UseUpdateOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useUpdateRating(options?: UseUpdateOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: Rating) => fetchPut(`${apiUrl}/${queryKey}/${model.id}`, model), {
        onMutate: async (model) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });
            await queryClient.cancelQueries({ queryKey: [queryKey, model.id] });

            const previousDataList = queryClient.getQueryData<Rating[]>([queryKey]);
            if (previousDataList)
                queryClient.setQueryData([queryKey], previousDataList.map(prev => prev.id === model.id ? model : prev));

            const previousData = queryClient.getQueryData<Rating>([queryKey, model.id]);
            if (previousData)
                queryClient.setQueryData([queryKey, model.id], model);

            return { previousDataList, previousData };
        },
        onError: (err: string, variables, context) => {
            if (context) {
                queryClient.setQueryData([queryKey], context.previousDataList);
                queryClient.setQueryData([queryKey, variables.id], context.previousData);
            }
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: async () => {
            if (options?.onSuccess)
                options.onSuccess();

            await queryClient.invalidateQueries({ queryKey: [queryKey, "search"] });
        }
    });
};

interface UseDeleteOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useDeleteRating(options?: UseDeleteOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((id: GridRowId) => fetchDelete(`${apiUrl}/${queryKey}/${id}`), {
        onMutate: async (id) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });

            const previousData = queryClient.getQueryData<Rating[]>([queryKey]);

            if (previousData) {
                queryClient.setQueryData([queryKey], previousData.filter(prev => prev.id !== id));

                return { previousData };
            }
        },
        onError: (err: string, id, context) => {
            if (context)
                queryClient.setQueryData([queryKey], context.previousData);
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: async () => {
            if (options?.onSuccess)
                options.onSuccess();

            await queryClient.invalidateQueries({ queryKey: [queryKey, "search"] });
        }
    });
};