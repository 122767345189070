import { ExpandMore, RestaurantMenu, SoupKitchenSharp } from "@mui/icons-material";
import { Avatar, Card, CardActions, CardHeader, Collapse, Grid, IconButton, IconButtonProps, Rating, styled } from "@mui/material";
import { orange } from "@mui/material/colors";
import moment from "moment";
import "moment/locale/cs";
import "moment/locale/en-gb";
import { useCallback, useContext, useState } from "react";
import { LayoutContext } from "wcz-layout";
import Menu from "../../models/Menu";

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMoreButton = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));

interface MenuCardProps {
    menu: Menu
}

export default function MenuCard(props: MenuCardProps) {
    const [expanded, setExpandend] = useState<boolean>(false);
    const { i18n } = useContext(LayoutContext);

    const getCreatedDate = useCallback((date: string) => {
        const localLocale = moment(date);
        localLocale.locale(i18n.language);
        return localLocale.fromNow();
    }, [i18n.language]);

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: orange[500] }} aria-label="recipe">
                        {props.menu.name?.includes("Soup") ? <SoupKitchenSharp /> : <RestaurantMenu />}
                    </Avatar>
                }
                title={props.menu.foodName}
                subheader={
                    <Grid container justifyContent="space-between">
                        <Grid item>{props.menu.name}</Grid>
                        {!!props.menu.price && <Grid item>{`${props.menu.price} Kč`}</Grid>}
                    </Grid>
                }
                sx={{ wordBreak: "break-word" }}
            />

            {!!props.menu.ratings?.filter(r => (!!r.comment || r.tasteOfFood)).length &&
                <CardActions disableSpacing sx={{ pt: 0 }}>
                    <ExpandMoreButton expand={expanded} onClick={() => setExpandend(!expanded)} aria-expanded={expanded} >
                        <ExpandMore />
                    </ExpandMoreButton>
                </CardActions>
            }

            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {props.menu.ratings?.map((rating) =>
                    <CardHeader key={rating.id}
                        avatar={<Rating value={rating.tasteOfFood} readOnly size="small" />}
                        title={rating.comment}
                        subheader={getCreatedDate(rating.created)}
                    />
                )}
            </Collapse>
        </Card>
    );
}