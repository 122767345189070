import { Send } from "@mui/icons-material";
import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useCallback, useContext, useState } from "react";
import { LayoutContext } from "wcz-layout";
import RatingModel from "../../models/Rating";
import RatingComment from "../../models/RatingComment";
import Reaction from "../../models/Reaction";
import { useUpdateRating } from "../../services/RatingService";
import { Dialog } from "../common/Dialog";
import { CommentCard } from "./comment/CommentCard";

interface RatingCommentsDialogProps {
    rating: RatingModel;
    setRating: (rating: RatingModel) => void;
    reactions: Reaction[];
    setReactions: (reactions: Reaction[]) => void;
}

export const RatingCommentsDialog: React.FC<RatingCommentsDialogProps> = ({ rating, setRating, reactions, setReactions }) => {
    const { t, user } = useContext(LayoutContext);
    const [ratingComment, setRatingComment] = useState<RatingComment>({} as RatingComment);

    const onClose = useCallback(() => {
        setRating({} as RatingModel);
        setRatingComment({} as RatingComment);
    }, []);

    const { mutate: update } = useUpdateRating({
        onSuccess: () => {
            setRatingComment({} as RatingComment);
            setTimeout(() => {
                const element = document.getElementById("add-comment");
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 200);
        }
    });

    const handleOnCommentChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setRatingComment({ ...ratingComment, value: event.target.value }), [ratingComment]);

    const handleOnAddComment = useCallback(() => {
        if (!ratingComment.value) return;

        update({
            ...rating,
            comments: [...rating.comments, { ...ratingComment, userId: user.id, userName: user.name }]
        });
    }, [rating, ratingComment]);

    const handleOnEnter = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
        if (!ratingComment.value) return;

        if (e.key === "Enter") {
            e.preventDefault();
            handleOnAddComment();
        }
    }, [ratingComment]);

    return (
        <Dialog open={!!rating.id} onClose={onClose} title={rating.foodName ?? t("Comments")} disableAutoClose={!!ratingComment.value}>
            <Grid container spacing={2}>
                {rating.comments?.sort((a, b) => a.created.localeCompare(b.created)).map(comment =>
                    <Grid item xs={12} key={comment.id}>
                        <CommentCard comment={comment} rating={rating} reactions={reactions} setReactions={setReactions} />
                    </Grid>
                )}

                {!rating.comments?.length && <Typography sx={{ px: 2, opacity: 0.5 }}>{t("NoCommentsYet")}</Typography>}

                <Grid item xs={12}>
                    <TextField
                        id="add-comment"
                        variant="filled"
                        fullWidth
                        color="secondary"
                        label={t("WriteComment")}
                        multiline
                        onChange={handleOnCommentChange}
                        value={ratingComment.value ?? ""}
                        onKeyDown={handleOnEnter}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end" onClick={handleOnAddComment} sx={{ cursor: "pointer" }}>
                                    <Send />
                                </InputAdornment>
                        }}

                    />
                    <Typography variant="caption" sx={{ opacity: 0.5 }}>{user.name ? t("YouArePostingAs", { userName: user.name }) : t("YouArePostingAsAnAnonymousUser")}</Typography>
                </Grid>
            </Grid>
        </Dialog>
    );
};