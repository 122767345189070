import { Add, BarChart, Home, MenuBook } from "@mui/icons-material";
import { lime } from "@mui/material/colors";
import { useMemo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { FileProvider } from "wcz-file";
import { LayoutProvider, LeftDrawerItem, isMobile, useUser } from "wcz-layout";
import packageJson from "../package.json";
import { AddRatingPage } from "./pages/AddRatingPage";
import { HomePage } from "./pages/HomePage";
import { fileUrl } from "./utils/BaseUrl";
import { MenuPage } from "./pages/MenuPage";
import TvPage from "./pages/TvPage";
import { Ratings } from "./pages/Ratings";

export const App: React.FC = () => {
    const user = useUser();

    const leftDrawerItems: LeftDrawerItem[] = useMemo(() => [
        { title: "Layout.Home", path: "/", icon: <Home fontSize="large" /> },
        { title: "AddRating", path: "/rating/add", icon: <Add fontSize="large" /> },
        { title: "Menu", path: "/menu", icon: <MenuBook fontSize="large" /> },
        { title: "Ratings", path: "/ratings", icon: <BarChart fontSize="large" />, hidden: isMobile },
    ], [user]);

    return (
        <BrowserRouter>
            <LayoutProvider title={packageJson.name} appVersion={packageJson.version} primaryColor={lime[600]} secondaryColor={lime[800]} leftDrawerItems={leftDrawerItems}>
                <FileProvider baseUrl={fileUrl}>
                    <Routes>
                        <Route path='/' element={<HomePage />} />
                        <Route path='/rating/add' element={<AddRatingPage />} />
                        <Route path='/menu' element={<MenuPage />} />
                        <Route path='/tv' element={<TvPage />} />
                        <Route path='/ratings' element={<Ratings />} />
                    </Routes>
                </FileProvider>
            </LayoutProvider>
        </BrowserRouter>
    );
};