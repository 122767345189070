import { isDevelopment } from "wcz-layout";

let apiUrl: string = "https://api.dev.wistron.eu/rate-canteen";
let fileUrl = "https://api.dev.wistron.eu/file";

if (!isDevelopment) {
    apiUrl = "https://api.wistron.eu/rate-canteen";
    fileUrl = "https://api.wistron.eu/file";
}

export { apiUrl, fileUrl };