import { Close } from "@mui/icons-material";
import { AppBar, Breakpoint, Dialog as MaterialDialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import { Fragment, ReactNode } from "react";
import { LayoutSnackbar } from "wcz-layout";

interface DialogProps {
    children?: ReactNode,
    title: string | ReactNode | null,
    open: boolean,
    onClose: () => void,
    color?: "success" | "info" | "warning" | "error" | "primary" | "secondary" | "inherit" | "default",
    maxWidth?: Breakpoint,
    actions?: ReactNode[],
    disablePadding?: boolean,
    disableAutoClose?: boolean
}

export const Dialog: React.FC<DialogProps> = ({ open, onClose, title, children, color, maxWidth, actions, disablePadding, disableAutoClose }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const isFetching = !!useIsFetching();
    const isMutating = !!useIsMutating();

    return (
        <MaterialDialog open={open} onClose={disableAutoClose ? undefined : onClose} fullScreen={fullScreen} fullWidth maxWidth={maxWidth} >
            {fullScreen ?
                <Fragment>
                    <AppBar sx={{ position: "relative", bgcolor: `${color}.main` }}>
                        <Toolbar>
                            <Typography sx={{ flex: 1 }} variant="h6">{title}</Typography>
                            <IconButton edge="start" onClick={onClose}>
                                <Close sx={{ color: `${color}.contrastText` }} />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {(isFetching || isMutating) && <LinearProgress color={color === "default" ? undefined : color} />}
                </Fragment>
                :
                <Fragment>
                    <Grid container justifyContent="space-between" sx={{ bgcolor: `${color}.main`, color: `${color}.contrastText` }}>
                        <Grid item xs>
                            <DialogTitle>{title}</DialogTitle>
                        </Grid>

                        <Grid item xs={2} sx={{ p: 1, textAlign: "right" }}>
                            <IconButton onClick={onClose}>
                                <Close fontSize="small" sx={{ color: `${color}.contrastText` }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {(isFetching || isMutating) && <LinearProgress color={color === "default" ? undefined : color} />}
                </Fragment>
            }

            {disablePadding ? children : <DialogContent>{children}</DialogContent>}

            {actions &&
                <DialogActions>
                    {actions.map(action => action)}
                </DialogActions>
            }

            <LayoutSnackbar />
        </MaterialDialog>
    );
};
