import { Typography } from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import moment from "moment";
import "moment/locale/cs";
import "moment/locale/en-gb";
import React, { useContext, useEffect, useMemo } from "react";
import { LayoutContext } from "wcz-layout";
import { GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import { useGetRatings } from "../services/RatingService";

export const Ratings: React.FC = () => {
    const { changeTitle, t, i18n } = useContext(LayoutContext);

    useEffect(() => changeTitle(t("Ratings")), [i18n.language]);

    const { data: ratings = [], isFetching } = useGetRatings();

    const columns = useMemo(() => [
        { field: "created", headerName: t("Created"), width: 150, type: "date", valueFormatter: ({ value }) => moment(value).formatDateTime(), valueGetter: ({ value }) => value && new Date(value) },
        { field: "foodName", headerName: t("Name"), width: 430, renderCell: ({ value, row }) => <Typography variant="body2" sx={{ my: 1 }}>{value}</Typography> },
        { field: "tasteOfFood", headerName: t("Food"), width: 100, type: "number", },
        { field: "staff", headerName: t("Price"), width: 100, type: "number", },
        { field: "price", headerName: t("Staff"), width: 100, type: "number", },
        { field: "comment", headerName: t("Comment"), width: 400, },
        { field: "likes", headerName: t("Likes"), width: 100, type: "number", },
        { field: "dislikes", headerName: t("Dislikes"), width: 100, type: "number", },
    ] as GridColDef[], [i18n.language]);

    return (
        <TableContainer>
            <DataGridPremium columns={columns} rows={ratings} slots={{ toolbar: GridToolbar }} loading={isFetching}
                slotProps={{ toolbar: { viewKey: "ratings-table", export: true } as GridToolbarProps }} getRowHeight={() => "auto"} />
        </TableContainer>
    );
};