import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { fetchGet } from "wcz-layout";
import Menu from "../models/Menu";
import { apiUrl } from "../utils/BaseUrl";

const queryKey: string = "Menus";

export function useGetMenus<TQueryFnData = Menu[], TError = string, TData = TQueryFnData>(options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([queryKey], ({ signal }) => fetchGet(`${apiUrl}/${queryKey}`, signal), options);
}

export function useGetTodayMenu<TQueryFnData = Menu[], TError = string, TData = TQueryFnData>(options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([queryKey, "today"], ({ signal }) => fetchGet(`${apiUrl}/${queryKey}/today`, signal), options);
}