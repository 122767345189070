import { Chat, ThumbDown, ThumbUp } from "@mui/icons-material";
import { Box, Button, Card, CardActions, CardContent, Divider, Rating, Typography } from "@mui/material";
import moment from "moment";
import "moment/locale/cs";
import "moment/locale/en-gb";
import { useCallback, useContext, useMemo } from "react";
import { FileViewer } from "wcz-file";
import { LayoutContext, persistStorage } from "wcz-layout";
import RatingModel from "../../models/Rating";
import Reaction from "../../models/Reaction";
import { ReactionType } from "../../models/enums/ReactionType";
import { useUpdateRating } from "../../services/RatingService";

interface RatingCardProps {
    rating: RatingModel;
    onCommentsClick: (rating: RatingModel) => () => void;
    reactions: Reaction[];
    setReactions: (reactions: Reaction[]) => void;
}

export const RatingCard: React.FC<RatingCardProps> = ({ rating, onCommentsClick, reactions, setReactions }) => {
    const { t, i18n } = useContext(LayoutContext);
    const reaction: Reaction | undefined = useMemo(() => reactions.find(r => r.ratingId === rating.id), [rating, reactions]);

    const { mutate: update } = useUpdateRating();

    const handleOnReactionClick = useCallback((type: ReactionType) => () => {
        if (reaction?.type === type) return;

        let updatedReactions: Reaction[] = reactions;

        let reactionCopy: Reaction | undefined = reaction;
        if (reactionCopy) {
            reactionCopy.type = type;
            updatedReactions = updatedReactions.map(r => r.ratingId === reactionCopy!.ratingId ? reactionCopy : r) as Reaction[];
            update({
                ...rating,
                likes: type === ReactionType.dislike ? rating.likes - 1 : rating.likes + 1,
                dislikes: type === ReactionType.like ? rating.dislikes - 1 : rating.dislikes + 1
            });
        } else {
            reactionCopy = { ratingId: rating.id, type: type };
            updatedReactions = [...updatedReactions ?? [], reactionCopy];
            update({
                ...rating,
                likes: type === ReactionType.like ? rating.likes + 1 : rating.likes,
                dislikes: type === ReactionType.dislike ? rating.dislikes + 1 : rating.dislikes
            });
        }

        setReactions(updatedReactions);
        persistStorage.setObject("reactions", updatedReactions);
    }, [reactions, rating, reaction]);

    const getCreatedDate = useCallback((date: string) => {
        const localLocale = moment(date);
        localLocale.locale(i18n.language);
        return localLocale.fromNow();
    }, [i18n.language]);

    return (
        <Card>
            <CardContent>
                <Box sx={{ mb: 0.5 }}>
                    <Typography variant="caption" sx={{ opacity: 0.5 }}>{rating.userName && `${rating.userName} `}{getCreatedDate(rating.created)}</Typography>
                </Box>

                {rating.foodName && <Typography variant="h6" gutterBottom>{rating.foodName}</Typography>}

                {rating.tasteOfFood && <Typography component="legend">{t("HowWasYourFood")}</Typography>}
                {rating.tasteOfFood && <Rating value={rating.tasteOfFood} readOnly size="small" />}

                {rating.price && <Typography component="legend">{t("Price")}</Typography>}
                {rating.price && <Rating value={rating.price} readOnly size="small" />}

                {rating.staff && <Typography component="legend">{t("HowWasTheStaff")}</Typography>}
                {rating.staff && <Rating value={rating.staff} readOnly size="small" />}

                {rating.comment && <Typography sx={{ mt: 1.5 }}>{rating.comment}</Typography>}

                {rating.hasFile && <FileViewer subId={rating.id} disableActions sx={{ mt: 1 }} />}
            </CardContent>

            <Divider />
            <CardActions disableSpacing>
                <Button color={reaction?.type === ReactionType.like ? "success" : "inherit"} startIcon={<ThumbUp />} onClick={handleOnReactionClick(ReactionType.like)}>{rating.likes}</Button>
                <Button color={reaction?.type === ReactionType.dislike ? "error" : "inherit"} startIcon={<ThumbDown />} onClick={handleOnReactionClick(ReactionType.dislike)}>{rating.dislikes}</Button>
                <Button
                    color="inherit"
                    onClick={onCommentsClick(rating)}
                    startIcon={<Chat />}
                    sx={{ marginLeft: "auto" }}>
                    {rating.comments?.length ? rating.comments.length : undefined} {t("Comments")}
                </Button>
            </CardActions>
        </Card>
    );
};