import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import packageJson from "../package.json";

LicenseInfo.setLicenseKey(packageJson.MuiXLicence);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

serviceWorkerRegistration.register();