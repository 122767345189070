import { Close, FilterList, Search } from "@mui/icons-material";
import { Chip, Divider, Grid, IconButton, InputBase, Paper } from "@mui/material";
import { Fragment, useCallback, useContext, useState } from "react";
import { LayoutContext } from "wcz-layout";

interface SearchInputProps {
    searchText: string;
    setSearchText: (searchText: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({ searchText, setSearchText }) => {
    const { t, user } = useContext(LayoutContext);
    const [filterOpen, setFilterOpen] = useState<boolean>(false);

    const clearSearchText = useCallback(() => setSearchText(""), []);

    const toggleFilter = useCallback(() => setFilterOpen(!filterOpen), [filterOpen]);

    const setWithImages = useCallback(() => setSearchText(t("WithImages")!), []);
    const setMyRatings = useCallback(() => setSearchText(t("MyRatings")!), []);

    return (
        <Fragment>
            <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center" }} >
                <Search sx={{ ml: 1 }} />

                <InputBase sx={{ ml: 1, flex: 1 }} placeholder={t("Search")!} onChange={e => setSearchText(e.target.value)} value={searchText} />

                <IconButton sx={{ p: "10px" }} onClick={clearSearchText} disabled={!searchText}>
                    <Close />
                </IconButton>

                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                <IconButton color={filterOpen ? "primary" : "default"} sx={{ p: "10px" }} onClick={toggleFilter}>
                    <FilterList />
                </IconButton>
            </Paper>

            {filterOpen &&
                <Grid container spacing={1} sx={{ mt: 0.3 }} >
                    <Grid item>
                        <Chip label={t("WithImages")} variant={searchText === t("WithImages") ? "filled" : "outlined"} onClick={setWithImages} />
                    </Grid>
                    {user.name &&
                        <Grid item>
                            <Chip label={t("MyRatings")} variant={searchText === t("MyRatings") ? "filled" : "outlined"} onClick={setMyRatings} />
                        </Grid>
                    }
                </Grid>
            }
        </Fragment>
    );
};
