import { ThumbDown, ThumbUp } from "@mui/icons-material";
import { Box, Button, Card, DialogContent, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import moment from "moment";
import { useCallback, useContext, useMemo } from "react";
import { LayoutContext, persistStorage } from "wcz-layout";
import Rating from "../../../models/Rating";
import RatingComment from "../../../models/RatingComment";
import Reaction from "../../../models/Reaction";
import { ReactionType } from "../../../models/enums/ReactionType";
import { useUpdateRating } from "../../../services/RatingService";

interface CommentCardProps {
    comment: RatingComment;
    rating: Rating;
    reactions: Reaction[];
    setReactions: (reactions: Reaction[]) => void;
}

export const CommentCard: React.FC<CommentCardProps> = ({ comment, rating, reactions, setReactions }) => {
    const { i18n } = useContext(LayoutContext);
    const reaction: Reaction | undefined = useMemo(() => reactions.find(r => r.commentId === comment.id), [comment, reactions]);

    const { mutate: update } = useUpdateRating();

    const handleOnReactionClick = useCallback((type: ReactionType) => () => {
        if (reaction?.type === type) return;

        let updatedReactions: Reaction[] = reactions;

        let reactionCopy: Reaction | undefined = reaction;
        if (reactionCopy) {
            reactionCopy.type = type;
            updatedReactions = updatedReactions.map(r => r.commentId === reactionCopy!.commentId ? reactionCopy : r) as Reaction[];
            update({
                ...rating,
                comments: rating.comments.map(c => c.id === comment.id ? {
                    ...c,
                    likes: type === ReactionType.dislike ? comment.likes - 1 : comment.likes + 1,
                    dislikes: type === ReactionType.like ? comment.dislikes - 1 : comment.dislikes + 1
                } : c),
            });
        } else {
            reactionCopy = { commentId: comment.id, type: type };
            updatedReactions = [...updatedReactions ?? [], reactionCopy];
            update({
                ...rating,
                comments: rating.comments.map(c => c.id === comment.id ? {
                    ...c,
                    likes: type === ReactionType.like ? c.likes + 1 : c.likes,
                    dislikes: type === ReactionType.dislike ? c.dislikes + 1 : c.dislikes
                } : c),
            });
        }

        setReactions(updatedReactions);
        persistStorage.setObject("reactions", updatedReactions);
    }, [reactions, rating, reaction]);

    const getCreatedDate = useCallback((date: string) => {
        const localLocale = moment(date);
        localLocale.locale(i18n.language);
        return localLocale.fromNow();
    }, [i18n.language]);

    return (
        <Card variant="outlined" sx={theme => ({ bgcolor: theme.palette.mode === "dark" ? grey[800] : grey[100] })}>
            <DialogContent sx={{ p: 1 }}>
                <Typography variant="caption" sx={{ opacity: 0.5 }}>{comment.userName && `${comment.userName} `}{getCreatedDate(comment.created)}</Typography>

                <Typography mt={1}>{comment.value}</Typography>
            </DialogContent>

            <Box sx={{ p: 0.3 }}>
                <Button color={reaction?.type === ReactionType.like ? "success" : "inherit"} startIcon={<ThumbUp />} onClick={handleOnReactionClick(ReactionType.like)}>{comment.likes}</Button>
                <Button color={reaction?.type === ReactionType.dislike ? "error" : "inherit"} startIcon={<ThumbDown />} onClick={handleOnReactionClick(ReactionType.dislike)}>{comment.dislikes}</Button>
            </Box>
        </Card>
    );
};