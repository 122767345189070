import { Star } from "@mui/icons-material";
import { Grid, LinearProgress, Typography } from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { LayoutContext } from "wcz-layout";

export default function TvPage() {
    const { changeTitle } = useContext(LayoutContext);
    const [timer, setTimer] = useState<number>(0);
    const [language, setLanguage] = useState<"csCz" | "en">("csCz");

    useEffect(() => {
        changeTitle("Rate canteen!");
        initTimer();
    }, []);

    const initTimer = () => {
        const interval = setInterval(() => setTimer((prev) => (prev >= 100 ? 0 : prev + 0.5)), 200);
        return () => clearInterval(interval);
    };

    useEffect(() => () => changeLanguage(), [timer]);

    const changeLanguage = () => {
        if (timer === 100) {
            setLanguage(prev => prev === "en" ? "csCz" : "en");
        }
    };

    return (
        <Fragment>
            <LinearProgress color="primary" variant="determinate" value={timer} sx={{ position: "fixed", top: { xs: 57, sm: 65 }, left: 0, right: 0, height: 10 }} />

            <Grid container>
                <Grid item xs={7} textAlign="center" sx={{ mt: 15 }}>
                    <img src="./logo512.png" alt="logo512" style={{ width: 155 }} />
                    {language === "csCz" ?
                        <Typography variant="h2" sx={{ mt: 1 }}>Jak vám dnes chutnalo?</Typography>
                        :
                        <Typography variant="h2" sx={{ mt: 1 }}>How was your food?</Typography>
                    }

                    {language === "csCz" ?
                        <Typography variant="h4" sx={{ mt: 1 }}>Ohodnoťte dnešní jídlo v aplikaci rate-canteen</Typography>
                        :
                        <Typography variant="h4" sx={{ mt: 1 }}>Rate today's food in the rate-canteen app</Typography>
                    }
                    <Typography variant="h4" sx={{ mt: 3 }}>
                        <Star sx={{ color: "gold" }} fontSize="inherit" />
                        <Star sx={{ color: "gold" }} fontSize="inherit" />
                        <Star sx={{ color: "gold" }} fontSize="inherit" />
                        <Star sx={{ color: "gold" }} fontSize="inherit" />
                        <Star sx={{ color: "gold" }} fontSize="inherit" />
                    </Typography>

                    <Typography variant="h4" sx={{ mt: 8 }}>rate-canteen.wistron.eu</Typography>
                </Grid>

                <Grid item xs={5} textAlign="center" sx={{ mt: 20 }}>
                    <QRCode value="https://rate-canteen.wistron.eu" size={400} />
                </Grid>
            </Grid>
        </Fragment>
    );
}